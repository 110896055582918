<template>
  <OsPanel
    id="finance-table"
    class="finance-table p-6"
    :class="{ 'finance-table--representative': !quote?.IsPersonalised }"
  >
    <OsAlert
      v-if="!quote || isQuoteError(quote)"
      color="gray"
      variant="soft"
      :description="quote?.Message || financeErrorMessage"
    />

    <template v-else>
      <header class="mb-6">
        <p v-if="includeTerms" class="text text-sm text-gray-500 mb-1">
          {{
            quote.IsPersonalised
              ? 'Your finance quote'
              : isPurchaseProduct
                ? 'Representative example'
                : 'Finance example'
          }}
        </p>
        <h6>
          {{
            includeTerms
              ? quote.Product
              : quote.IsPersonalised
                ? 'Your finance quote'
                : isPurchaseProduct
                  ? 'Representative example'
                  : 'Finance example'
          }}
        </h6>
        <p v-if="includeTerms" class="text text-sm mt-2">
          {{ productDescription }}
        </p>
      </header>

      <OsAlert
        v-if="includeTerms && notifications.length"
        color="gray"
        variant="soft"
      >
        <template #description>
          <FinanceNotifications :notifications="notifications" />
        </template>
      </OsAlert>

      <!-- Purchase Product -->
      <template v-if="isPurchaseProduct">
        <dl class="finance-table__group finance-table__group--bordered">
          <dt class="text text-base text-gray-500">
            {{ priceLabel }}
          </dt>
          <dd
            class="finance-table__value"
            :class="{
              'text-lg bold text-gray-900': quote.IsPersonalised
            }"
          >
            {{ formatGBP(quote.OTR) }}
          </dd>
          <dt class="text text-base text-gray-500">
            {{ quote.DurationOfAgreement }} Monthly payments of*
          </dt>
          <dd
            class="finance-table__value"
            :class="{
              'text-lg bold text-gray-900': quote.IsPersonalised
            }"
          >
            {{ formatGBP(quote.MonthlyPayment) }}
          </dd>
        </dl>
        <dl class="finance-table__group">
          <dt class="text text-base text-gray-500">Customer deposit</dt>
          <dd class="finance-table__value">
            {{ formatGBP(quote.CustomerDeposit) }}
          </dd>
          <template v-if="quote.TotalDepositContribution">
            <dt class="text text-base text-gray-500">
              Finance deposit contribution
            </dt>
            <dd class="finance-table__value">
              {{ formatGBP(quote.TotalDepositContribution) }}
            </dd>
          </template>
          <template v-if="quote.PartExchange">
            <dt class="text text-base text-gray-500">Indicative car value</dt>
            <dd class="finance-table__value">
              {{ formatGBP(quote.PartExchange) }}
            </dd>
          </template>
          <template v-if="quote.Settlement">
            <dt class="text text-base text-gray-500">Settlement figure</dt>
            <dd class="finance-table__value">
              {{ formatGBP(quote.Settlement) }}
            </dd>
          </template>
          <template v-if="shouldShowTotalDeposit">
            <dt class="text text-base text-gray-500">Total deposit</dt>
            <dd
              class="finance-table__value"
              :class="{
                'bold text-gray-900': quote.IsPersonalised
              }"
            >
              {{ formatGBP(quote.TotalDeposit) }}
            </dd>
          </template>
        </dl>
        <dl class="finance-table__group">
          <template v-if="quote.PurchaseActivationFee">
            <dt class="text text-base text-gray-500">
              Purchase activation fee
            </dt>
            <dd class="finance-table__value">
              {{ formatGBP(quote.PurchaseActivationFee) }}
            </dd>
          </template>
          <dt class="text text-base text-gray-500">Amount of credit</dt>
          <dd class="finance-table__value">
            {{ formatGBP(quote.AmountOfCredit) }}
          </dd>
          <template v-if="quote.OptionalPurchasePayment">
            <dt class="text text-base text-gray-500">
              Optional purchase payment
            </dt>
            <dd class="finance-table__value">
              {{ formatGBP(quote.OptionalPurchasePayment) }}
            </dd>
          </template>
          <dt class="text text-base text-gray-500">Total amount payable</dt>
          <dd
            class="finance-table__value"
            :class="{
              'bold text-gray-900': quote.IsPersonalised
            }"
          >
            {{ formatGBP(quote.TotalAmountPayable) }}
          </dd>
        </dl>
        <dl class="finance-table__group">
          <dt class="text text-base text-gray-500">Duration of agreement</dt>
          <dd class="finance-table__value">
            {{ quote.DurationOfAgreement }} months
          </dd>
          <template v-if="quote.IsBCP || quote.IsPCP">
            <dt class="text text-base text-gray-500">Annual mileage</dt>
            <dd class="finance-table__value">
              {{ formatWithCommas(quote.AnnualMileage) }} miles
            </dd>
          </template>
          <template v-if="!quote.IsBHP && !quote.IsPHP">
            <dt class="text text-base text-gray-500">Excess mileage charge</dt>
            <dd class="finance-table__value">
              {{ quote.ExcessMileageCharge }}p per mile
            </dd>
          </template>
          <dt class="text text-base text-gray-500">Fixed interest rate</dt>
          <dd class="finance-table__value">{{ quote.FixedInterestRate }}%</dd>
          <dt class="text text-base text-gray-500">Representative APR</dt>
          <dd class="finance-table__value">{{ quote.APR }}% APR</dd>
        </dl>
      </template>

      <!-- Hire/Lease Product -->
      <template v-else>
        <dl class="finance-table__group finance-table__group--bordered">
          <dt class="text text-base text-gray-500">
            Monthly rental*
            {{ quote.IsCH || quote.IsBOL ? '(excl. VAT)' : null }}
          </dt>
          <dd
            class="finance-table__value"
            :class="{
              'text-lg bold text-gray-900': quote.IsPersonalised
            }"
          >
            {{ formatGBP(quote.MonthlyPayment) }}
          </dd>
          <dt class="text text-base text-gray-500">
            Advance rental
            {{ quote.IsCH || quote.IsBOL ? '(excl. VAT)' : null }}
          </dt>
          <dd class="finance-table__value">
            {{ formatGBP(quote.LumpSum ?? quote.CustomerDeposit) }}
          </dd>
          <template v-if="quote.TotalDepositContribution">
            <dt class="text text-base text-gray-500">
              Finance contribution
              {{ quote.IsCH || quote.IsBOL ? '(excl. VAT)' : null }}
            </dt>
            <dd class="finance-table__value">
              {{ formatGBP(quote.TotalDepositContribution) }}
            </dd>
          </template>
          <template v-if="quote.PartExchange">
            <dt class="text text-base text-gray-500">
              Indicative car value
              {{ quote.IsCH || quote.IsBOL ? '(excl. VAT)' : null }}
            </dt>
            <dd class="finance-table__value">
              {{ formatGBP(quote.PartExchange) }}
            </dd>
          </template>
          <template v-if="quote.Settlement">
            <dt class="text text-base text-gray-500">Settlement figure</dt>
            <dd class="finance-table__value">
              {{ formatGBP(quote.Settlement) }}
            </dd>
          </template>
        </dl>
        <dl class="finance-table__group">
          <dt class="text text-base text-gray-500">Duration of agreement</dt>
          <dd class="finance-table__value">
            {{ quote.DurationOfAgreement }} months
          </dd>
          <template v-if="quote.AnnualMileage">
            <dt class="text text-base text-gray-500">Annual mileage</dt>
            <dd class="finance-table__value">
              {{ formatWithCommas(quote.AnnualMileage) }} miles
            </dd>
          </template>
          <template v-if="!quote.IsPHP && !quote.IsBHP">
            <dt class="text text-base text-gray-500">
              Excess mileage charge
              {{ quote.IsCH || quote.IsBOL ? '(excl. VAT)' : null }}
            </dt>
            <dd class="finance-table__value">
              {{ quote.ExcessMileageCharge }}p per mile
            </dd>
          </template>
        </dl>
        <dl v-if="quote.AdvanceRentals" class="finance-table__group">
          <dt class="text text-base text-gray-500">Payment profile</dt>
          <dd class="finance-table__value">
            {{ quote.PaymentProfile }}
          </dd>
        </dl>
      </template>

      <OsButton
        v-if="isAvailableForFinance && canCheckFinance && !isKioskMode"
        class="os-button--block mt-4"
        @click="onCheckFinanceEligibility"
      >
        <OsIcon name="Pound" class="text-blue-600" />
        Check finance eligibility
      </OsButton>

      <footer v-if="includeTerms" class="mt-4">
        <div v-if="quote.TermsAndConditions" class="mt-6">
          <p class="text-base bold">Terms &amp; Conditions</p>
          <p
            class="text text-sm text-gray-500 pre-line"
            v-html="useEscape(quote.TermsAndConditions)"
          />
        </div>
      </footer>
    </template>
  </OsPanel>
</template>

<script lang="ts">
import errorMessages from '~/constants/errorMessages'
import { useFormsStore } from '~/stores/forms/formsStore'
import { TrackingCategoryKey } from '~/types/dataLayer'
import { EFinanceProductDescriptions } from '~/types/finance/product'
import { IQuoteDto, IQuoteError } from '~/types/finance/quote'
import { EVehicleType, NewVehicle, UsedVehicle } from '~/types/vehicle'

export default {
  name: 'FinanceTable',

  props: {
    quote: {
      type: Object as PropType<IQuoteDto | IQuoteError | undefined>,
      required: true
    },
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: true
    },
    includeTerms: {
      type: Boolean,
      default: true
    },

    canCheckFinance: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const { trackClick } = useTracking()
    const { isNew } = useVehicle(toRef(props, 'vehicle'))
    const isPurchaseProduct = computed<boolean>(() => {
      if (!props.quote || isQuoteError(props.quote)) return true

      const { IsPCP, IsBHP, IsPHP, IsBCP } = props.quote

      return [IsPCP, IsBHP, IsPHP, IsBCP].some((product) => product)
    })

    const productDescription = computed<string>(() => {
      if (!props.quote || isQuoteError(props.quote)) return ''

      return props.quote.IsPCP
        ? EFinanceProductDescriptions.PCP
        : props.quote.IsPCH
          ? EFinanceProductDescriptions.PCH
          : props.quote.IsCH
            ? EFinanceProductDescriptions.CH
            : props.quote.IsPHP
              ? EFinanceProductDescriptions.PHP
              : props.quote.IsPOL
                ? EFinanceProductDescriptions.POL
                : props.quote.IsBCP
                  ? EFinanceProductDescriptions.BCP
                  : props.quote.IsBOL
                    ? EFinanceProductDescriptions.BOL
                    : props.quote.IsBHP
                      ? EFinanceProductDescriptions.BHP
                      : ''
    })

    const isKioskMode = useState('kioskmode')

    const { isAvailableForFinance, checkFinanceEligibility } = useOcd()
    const formsStore = useFormsStore()

    const trackingCategory = inject(TrackingCategoryKey, '')

    const onCheckFinanceEligibility = async () => {
      if (!isAvailableForFinance.value) return

      await formsStore.setVehicle(props.vehicle)

      checkFinanceEligibility()

      trackClick({
        category: `${trackingCategory}_check_finance_eligibility`,
        action: ''
      })
    }

    const { notifications } = useFinanceNotifications(toRef(props, 'quote'))

    const priceLabel = computed<string>(() => {
      const isNew = props.vehicle.VehicleType === EVehicleType.New

      if (parseTotalOffer(props.vehicle.TotalOfferValue) > 0) {
        return isNew ? 'Offer price' : 'Retail offer price'
      }

      return isNew ? 'On the road price' : 'Retail price'
    })

    const shouldShowTotalDeposit = computed(() => {
      return (
        (isPurchaseProduct.value && props.quote?.TotalDepositContribution) ||
        props.quote?.PartExchange
      )
    })
    return {
      isNew,
      isKioskMode,
      isPurchaseProduct,
      productDescription,
      isAvailableForFinance,
      onCheckFinanceEligibility,
      financeErrorMessage: errorMessages.finance.notFound,
      notifications,
      shouldShowTotalDeposit,
      priceLabel
    }
  }
}
</script>

<style lang="scss" scoped>
.finance-table {
  margin-top: rem(8) !important;
}

.finance-table__group {
  align-items: end;
  display: grid;
  grid-template-columns: 1fr 110px;
  gap: rem(12);
  margin-bottom: rem(20);
  padding-bottom: rem(20);

  &--bordered {
    border-bottom: 1px solid $grey--light;
  }

  .finance-table--representative & {
    border: 0;
    margin-bottom: rem(12);
    padding-bottom: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.finance-table__value {
  font-size: rem(14);
  color: $grey--darkest;
  justify-self: end;

  &.is-bold {
    font-family: $mb-font-text-bold;
    font-size: rem(18);
  }
}

.finance-table {
  .os-button:last-child {
    margin: 0 -16px -16px -16px;
    width: calc(100% + 32px);
  }
}
</style>
